
  import { Component, Prop, Mixins, Watch, Emit } from "vue-property-decorator";
  import { HasDesktopPart } from "@/mixins/properties/has-desktop-part";
  import DocumentFolder from "@/components/document/folder.vue";
  import { bus } from "@/main";
  import { isStoredMedia } from "@/utils/media-helpers";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
    },
  })
  export default class PagePropertyPartDesktopFile extends Mixins(HasDesktopPart) {
    @Prop({ default: null }) value!: string | null;

    @Prop({ default: null }) folder!: DocumentFolder;

    /**
     * -----------------
     * Private variables
     * -----------------
     */

    bus = bus;

    uploading = false;

    localValue: Media[] = [];

    previews: Media[] = [];

    get mediaList() {
      return [...this.previews, ...this.localValue];
    }

    handleFileUploaded(media: Media[]) {
      this.uploading = true;

      this.localValue = media;

      this.handleInput();
    }

    removeDocument(index: number) {
      this.localValue.splice(index, 1);

      this.handleInput();
    }

    handleFilePreview(media: Media) {
      if (!isStoredMedia(media)) {
        return;
      }

      bus.$emit("show-preview-media", media);
    }

    @Emit("input")
    handleInput() {
      this.uploading = false;

      return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: Media[]) {
      if (!newValue || !Array.isArray(newValue)) {
        return;
      }

      this.localValue = [...this.localValue, ...newValue]
        .filter((media: Media) => !isStoredMedia(media))
        .map((media: Media) => ({ ...media, can_preview: false }))
        .filter((media: Media, index: number, self: Media[]) => self.findIndex((m: Media) => m.uuid === media.uuid) === index);

      this.previews = [...this.previews, ...newValue]
        .filter((media: Media) => isStoredMedia(media))
        .map((media: Media) => ({ ...media, can_preview: true }))
        .filter((media: Media, index: number, self: Media[]) => self.findIndex((m: Media) => m.uuid === media.uuid) === index);
    }
  }
